const logotext = "RAYNAUD";
const meta = {
    title: "Raynaud Cornille",
    description: "Ik ben Raynaud, Computer Science Engineer",
};

const introdata = {
    title: "Ik ben Raynaud Cornille",
    animated: {
            first: "Ik maak website's",
        second: "Ik codeer mobiele apps",
        third: "Ik integreer AI voor kmo's",
    },
    description: "Navigeer naar de toekomst: Krachtige digitale oplossingen voor KMO's, gedreven door innovatie in webontwikkeling, mobiele app-creatie en geavanceerde AI-integratie.",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "Over mij",
    aboutme: "Als gepassioneerde computer science ingenieur ben ik voortdurend bezig met het verkennen van de grenzen van digitale innovatie. Mijn reis begon met een diepgaande nieuwsgierigheid naar de werking van computers en software, en groeide uit tot een toewijding aan het creëren van impactvolle oplossingen voor kleine en middelgrote ondernemingen.\n" +
        "\n" +
        "Met een focus op webontwikkeling, mobiele app-creatie en geavanceerde AI-integratie, streef ik ernaar om technologie toegankelijk te maken en te benutten voor groei en efficiëntie. Mijn aanpak combineert creativiteit met technische expertise, waarbij ik op maat gemaakte oplossingen ontwikkel die de unieke behoeften van mijn klanten weerspiegelen.\n" +
        "\n" +
        "Door voortdurend te leren en te innoveren, blijf ik up-to-date met de nieuwste trends en technologieën in de sector. Mijn doel is om niet alleen hoogwaardige producten te leveren, maar ook duurzame partnerschappen op te bouwen die gedijen op vertrouwen, transparantie en gedeelde groei.\n" +
        "\n" +
        "Ik geloof in de kracht van technologie om de wereld te transformeren, en ik ben vastbesloten om bij te dragen aan die transformatie door middel van mijn werk. Laten we samen bouwen aan een toekomst die gedreven wordt door innovatie en mogelijkheden.",
};
const worktimeline = [{
    jobtitle: "Designer of week",
    where: "YAdfi",
    date: "2020",
},
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

// const skills = [{
//     name: "Python",
//     value: 90,
// },
//     {
//         name: "Djano",
//         value: 85,
//     },
//     {
//         name: "Javascript",
//         value: 80,
//     },
//     {
//         name: "React",
//         value: 60,
//     },
//     {
//         name: "Jquery",
//         value: 85,
//     },
// ];

const services = [{
    title: "Design en Maken van Websites",
    description: "Het proces van het ontwerpen en maken van websites begint met een grondige analyse van de doelstellingen en behoeften van de klant, gevolgd door het ontwerpen van wireframes en mock-ups om de structuur en lay-out te visualiseren. Vervolgens wordt de website waarbij functionaliteiten worden geïmplementeerd en getest voor optimale prestaties op verschillende apparaten en browsers. Na lancering wordt de website regelmatig onderhouden en geoptimaliseerd om de veiligheid, prestaties en relevantie te behouden.",
},
    {
        title: "Design en Maken van Mobiele Apps",
        description: "Het creëren van mobiele apps begint met een grondige analyse van de doelstellingen en gebruikersbehoeften. Vervolgens wordt een intuïtief ontwerp gemaakt dat de gebruikerservaring optimaliseert en de gewenste functionaliteiten benadrukt. De app wordt ontwikkeld waarbij aandacht wordt besteed aan responsiviteit en prestaties. Na lancering wordt de app regelmatig geüpdatet en verbeterd om aan veranderende behoeften te voldoen en een naadloze gebruikerservaring te garanderen.",
    },
    {
        title: "AI integratie in diverse systemen",
        description: "De integratie van Artificial Intelligence (AI) in digitale oplossingen biedt KMO's ongekende mogelijkheden. Door geavanceerde algoritmen en machine learning toe te passen, kunnen bedrijven efficiëntie verbeteren, kosten verlagen en concurrentievoordeel behalen. Als computer science ingenieur help ik KMO's AI te integreren in webapplicaties, mobiele apps en andere digitale platforms om waarde toe te voegen en groei te stimuleren.",
    },
];

const dataportfolio = [{
    img: "https://picsum.photos/400/?grayscale",
    description: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
    {
        img: "https://picsum.photos/400/800/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "Raynaud.cornille@gmail.com",
    YOUR_FONE: "+32471452437",
    description: "Contacteer me vrijblijvend en laten we samen werken aan jouw volgende digitale project!",
    // creat an emailjs.com account
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/Raynaud123",
    facebook: "https://facebook.com",
    linkedin: "https://be.linkedin.com/in/raynaud-cornille-527a8222",
    twitter: "https://twitter.com/raynaudcornille",
};
export {
    meta,
    dataabout,
    dataportfolio,
    //worktimeline,
    //skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};